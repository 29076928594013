// Get the user's wallet address from local storage
export const getUserWalletAddress = () => {
  return localStorage.getItem('userWalletAddress');
};

export const fetchImage = (oneofone,source,bg,oneOff,total, current,context,canvas,size,setImageURL,setPlainImage,logo) => {
  return new Promise(resolve => {
    let oneOffImg = null;
    let sizeW = size;
    let sizeH = size;
    let image = new Image();
    image.src = source;

    if(bg) {
      let imageBG = new Image();
      imageBG.src = bg;

      sizeW = size
      sizeH = size*2 
      
      if(oneOff) {
        oneOffImg = new Image();
        oneOffImg.src = oneOff;
      }

      const onLoad = () => {
        if(oneOff) {
            if(image.complete && imageBG.complete && oneOffImg.complete) {
              canvas.width = size;
              canvas.height = size * 2;

              context.globalAlpha = 1;
              context.drawImage(imageBG, 0, 0, sizeW, sizeH);
              context.drawImage(image, 0, size, size, size);
              context.drawImage(oneOffImg, 0, 0, sizeW, sizeH);

              resolve(canvas.toDataURL());
            }
        } else if (image.complete && imageBG.complete) {
          canvas.width = size;
          canvas.height = size * 2;

          context.globalAlpha = 1;
          context.drawImage(imageBG, 0, 0, sizeW, sizeH);
          context.drawImage(image, 0, size, size, size);

          resolve(canvas.toDataURL());
        }
      };

      image.onload = onLoad;
      imageBG.onload = onLoad;
    } else {
      if(oneofone) {
        sizeW = size
        sizeH = size*2
      }

      if(logo) {
        if(logo !== 'default') {
          let logoimg = new Image();
          logoimg.src = logo;

          const onLoad = () => {
              if(logoimg.complete) {
                context.globalAlpha = 1;
                context.drawImage(logoimg, 0, 0, size, size*2);

                resolve(canvas.toDataURL());
              }
          };
          logoimg.onload = onLoad;
        } else {
              // console.log(source)
          const onLoad = () => {
              if(image.complete) {
                context.globalAlpha = 1;
                context.drawImage(image, 0, 0, size, size*2);

                resolve(canvas.toDataURL());
              }
          };
          image.onload = onLoad;
        }
      } else {
        image.onload = () => {
          context.globalAlpha = 1;
          context.drawImage(image, 0, 0, sizeW, sizeH);
          resolve();
        } 

      }
      
      // setTimeout(() => {
      //   setImageURL(image)
      // }, 500)
  
    }

    let plain = canvas.toDataURL('image/png')

    setTimeout(() => {
      setImageURL(image)
      setPlainImage(plain)
    }, 500)

    image.setAttribute('crossorigin', 'anonymous');
  });
};