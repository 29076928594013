import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../logo.svg';
import ConnectButton from '../components/ConnectButton';

function Navbar(props) {
  return (
    <header>
        <div className="container">
            <Link className="logo" to="/">
              <Logo />
            </Link>

            <nav>
                <Link 
                  className="add-collection" 
                  to="https://www.x.com/tokensnapxyz"
                >Add Your Collection</Link>

                <ConnectButton 
                  address={props.address}
                  setAddress={props.setAddress}
                />
            </nav>
        </div>
    </header>
  );
}

export default Navbar;