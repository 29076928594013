import React, { useState, useEffect } from 'react';
import Asset from './Asset';
import axios from 'axios';

function WalletContents(props) {
    const [imageURL, setImageURL] = useState('');
    const [error, setError] = useState('');
    const [assets, setAssets] = useState('');

    const userAddress = props.wallet;
    const osApiKey = props.apikey;
    const osContractSlug = props.contractslug;
    const osContract = props.contract;

    const walletOptions = {
      method: 'GET',
      url: 'https://api.opensea.io/api/v2/chain/ethereum/account/'+userAddress+'/nfts?collection='+osContractSlug,
      params: {
        limit: '200',
      },
      headers: {
        'X-API-KEY': osApiKey,
        'Content-Type': 'application/json' // Add this if necessary
      }
    };

    useEffect(() => {
      axios
        .request(walletOptions)
        .then(function (response) {
          setAssets(response.data.nfts);

          if(response.data.nfts.length === 0) {
            setError('No assets of this collection in your wallet.');
          }
        })
        .catch(function (error) {
          setError('Error getting assets from wallet. '+error);
      });
    }, []);

    return (
        <div className="wallet-contents">
          {error && <p className="throw_error">{error}</p>}
          {assets.length >= 1 &&
            assets.map((asset) => {
                return (
                  <Asset 
                    key={asset.identifier} 
                    asset={asset} 
                    image={asset.image_url}
                    size={props.wallpaper.size}
                    rares={props.wallpaper.rares}
                    oneoffs={props.wallpaper.oneoffs}
                    component={props.component}
                    imageURL={props.imageURL}
                    setImageURL={props.setImageURL}
                    toggled={props.toggled}
                    trait={props.trait}
                    plainImage={props.plainImage}
                    setPlainImage={props.setPlainImage}
                    setToggle={props.setToggle}
                    savedTrait={props.savedTrait}
                    setSavedTrait={props.setSavedTrait}
                    contract={osContract}
                    apiKey={osApiKey}
                  />
                )
            })
          }
        </div>
    )
}

export default WalletContents;