import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WalletContents from '../components/WalletContents';
import ConnectButton from '../components/ConnectButton';
import { getUserWalletAddress,fetchImage } from '../components/Utils';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Wallpaper({wallpaper,storedAddress}) {
    const apikey = wallpaper.api;
    const contract = wallpaper.contract;
    const contractslug = wallpaper.contractslug;
    const name = wallpaper.name;
    const theTrait = wallpaper.trait;
    const theSize = wallpaper.size;
    const component = wallpaper.component;
    const rares = wallpaper.rares;
    const oneoffs = wallpaper.oneoffs;
    const total = wallpaper.total;
    const logo = wallpaper.logo;

    const canvasRef = useRef(null)
    const canvas = canvasRef.current   
    const [tokenId, setTokenId] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [plainImage, setPlainImage] = useState('');
    const [error, setError] = useState('');
    const [address, setAddress] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [savedTrait, setSavedTrait] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [toggled, setToggle] = useState('default');
    
    const userAddress = getUserWalletAddress();
    const regExp = /^0[0-9].*$/;

    useEffect(() => {
      if (userAddress) {
        setCollapsed(true)
        setAddress(userAddress)
      }
    }, []);

    // TOGGLE IMAGE
    let toggleButton = (type) => {
      setToggle(type)
      rerunFetchImage(type)
    }

    const rerunFetchImage = (type) => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      
      if(type === 'logo') {
        let logoURL = require('../images/wallpapers/'+component+'/logo.png')                
        
        if(logoURL !== null) {
          fetchImage(false,imageURL,null,null, 2, 1,context,canvas,theSize,setImageURL,setPlainImage,logoURL)
        }
      // ELSE DEFUALT
      } else {
        fetchImage(false,plainImage,null,null, 1, 0,context,canvas,theSize,setImageURL,setPlainImage,'default')
      }

    } // fetch image

    const handleSubmit = async (e) => {
      e.preventDefault();

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if(tokenId === '') {
          setError('ID cannot be blank');
      } else if(regExp.test(tokenId) == true) {
          setError('Remove the 0 at the beginning');
      } else if(tokenId > total) {
          setError('Choose an ID between 1 and '+total);
      } else {
          setToggle('default')
          setError(null);
          setIsLoading(true);
          const getTokenBySearch = {
            method: 'GET',
            url: 'https://api.opensea.io/v2/chain/ethereum/contract/'+contract+'/nfts/'+tokenId,
            headers: {accept: 'application/json', 'X-API-KEY': apikey}
          }

          axios
          .request(getTokenBySearch)
          .then(function (response) {
            let data = response.data;
            let imageURL = data.nft.image_url;
            let cleanTraitPath = '';
            let oneOffPath = '';

            if(rares.includes(tokenId)) {
               imageURL = require('../images/wallpapers/'+component+'/bg_'+tokenId+'.jpg')

              setImageURL(imageURL)
              fetchImage(true,imageURL,false,false, 1, 0,ctx,canvas,theSize,setImageURL,setPlainImage,null)
            } else {
              data.nft.traits.map((type) => {
                if(oneoffs) {
                  oneoffs.forEach(oneoff => {
                    Object.keys(oneoff).forEach(traitType => {
                        if(type.trait_type == traitType) {             
                          const ooTraits = oneoff[traitType];
                          
                          Object.keys(ooTraits).forEach(traitName => {
                            if(traitName == type.value) {
                              oneOffPath = require('../images/wallpapers/'+component+'/'+ooTraits[traitName]+'.png')
                            }
                          });
                        }
                    });
                  });
                }
                // the rest
                if(type.trait_type == theTrait) { 
                  const cleanTrait = type.value.replace(/\s+/g, '-').toLowerCase()
                  setSavedTrait(cleanTrait)

                  cleanTraitPath = require('../images/wallpapers/'+component+'/bg_'+cleanTrait+'.png')
                }
              })
              
              setImageURL(imageURL)
              fetchImage(false,imageURL, cleanTraitPath,oneOffPath, 1, 0,ctx,canvas,theSize,setImageURL,setPlainImage,null)
            }

            setIsLoading(false)
          })
          .catch(function (error) {
            setError('Error getting asset. '+error);
          });
      } // else
    } // function

    const downloadAsset = () => {
        const dataURL = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'download.png';

        a.click();
    }

    return (
      <div className="page-wrap wallpaper">
        <Header 
        address={address}
        setAddress={setAddress}
        />

        <div className="gm-wrapper">
          <div className="gm">

            <div className="download-left">
            <div className="page-intro">
              <h1><strong>{name}</strong> Wallpapers</h1>
            </div>
              <div 
                className={
                  (collapsed ? "collapsed " : "") + "inner top"
                }
              >
                <form id="download-form" name="download-form" onSubmit={handleSubmit}>
                  <h2>Find Asset by <strong>Token ID</strong></h2>
                  <div className="field number">
                    <input
                      name="theid" 
                      id="theid" 
                      placeholder="Token ID" 
                      pattern="\d{1,4}" 
                      maxLength="4"
                      value={tokenId}
                      onChange={(e) => setTokenId(e.target.value)}
                    />
                  <button className="btn" type="submit"><span>Generate Image</span></button>
                  </div>{/* FIELD */}
                  {error && <p className="throw_error">{error}</p>}
                </form>{/* FORM */}
                  {address && (
                    <button 
                      className="btn toggle-top"
                      onClick={() => setCollapsed(false)}
                    ><FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />Find Asset by Token</button>
                  )}
                </div>{/* INNER */}

                {address
                  ? 
                  <div className="inner bottom">
                    <h2>Find Asset by <strong>Wallet Contents</strong></h2>

                    <WalletContents 
                      wallet={address} 
                      apikey={apikey}
                      contract={contract}
                      contractslug={contractslug}
                      imageURL={imageURL}
                      plainImage={plainImage}
                      setPlainImage={setPlainImage}
                      setImageURL={setImageURL}
                      component={component}
                      trait={theTrait}
                      toggled={toggled}
                      setToggle={setToggle}
                      savedTrait={savedTrait}
                      setSavedTrait={setSavedTrait}
                      wallpaper={wallpaper}
                    />

                  </div>
                  : 
                  <div className="connect-to-inner">
                    <p>Want to choose from your wallet instead?</p>
                    <ConnectButton 
                    address={address}
                    setAddress={setAddress}
                    />
                  </div>
                }

                {imageURL && logo && 
                  <div className="image-toggle">
                    <button 
                      className={toggled == 'default' ? "active" : ""}
                      onClick={() => toggleButton('default')}
                    >Image Only</button>

                    <button 
                      className={toggled == 'logo' ? "active" : ""}
                      onClick={() => toggleButton('logo')}
                    >Add Logo</button>
                  </div>
                }
            </div>{/* LEFT */}

            <div className="canvas-outter-wrap">
              <div className="wrap">
                <div 
                className={isLoading ? "canvas-wallpaper-wrap loading" : "canvas-wallpaper-wrap"}
                id="canvas-wrap"
                >
                  <div className="canvas-wrap">
                    <canvas
                      ref={canvasRef}
                      id="imageCanvas"
                      width='1500'
                      height='3000'
                    ></canvas>

                    <div className="phone-style-wrap">
                      <div className="phone-time">6:09</div>
                      <div className="phone-date">Saturday, February 10</div>
                      <div className="phone-lockscreen"></div>
                    </div>
                  {imageURL && (
                    <>
                    <img
                      crossOrigin="anonymous"
                      src={imageURL}
                      alt="Token"
                      style={{ display: 'none' }}
                    />
                    </>
                  )}
                </div>{/* CANVAS WRAP */}
                  {imageURL && (
                    <button 
                      className="download btn blue"
                      onClick={downloadAsset}
                    >Download Image</button>
                  )}
              </div>
            </div>
            </div>

          </div>{/* GM */}

          <Footer />
        </div>
      </div>
    )
}

export default Wallpaper;