import React, { useState, useEffect } from 'react';
import { fetchImage } from '../components/Utils';
import axios from 'axios';

function Asset(props) {
  const [assetTraits, setAssetTraits] = useState('');
  const [error, setError] = useState('');
  const assetID = props.asset.identifier;
  const rares = props.rares;
  const oneoffs = props.oneoffs;
  const logo = props.logo;

  const getSingleAssetTraits = {
    method: 'GET',
    url: 'https://api.opensea.io/v2/chain/ethereum/contract/'+props.contract+'/nfts/'+assetID,
    headers: {accept: 'application/json', 'X-API-KEY': props.apiKey}
  }

  const clickAsset = (image) => { 
    let canvas = document.getElementById('imageCanvas');
    let context = canvas.getContext('2d');  

    axios
      .request(getSingleAssetTraits)
      .then(function (response) {
        props.setToggle('default')

        let data = response.data;
        let imageURL = data.nft.image_url;
        let cleanTraitPath = '';
        let oneOffPath = '';

        if(rares.includes(assetID)) {
           imageURL = require('../images/wallpapers/'+component+'/bg_'+assetID+'.jpg')

          props.setImageURL(imageURL)
          fetchImage(true,imageURL,false,false, 1, 0,context,canvas,props.size,props.setImageURL,props.setPlainImage,null)
        } else {
          data.nft.traits.map((type) => {
            if(oneoffs) {
              oneoffs.forEach(oneoff => {
                Object.keys(oneoff).forEach(traitType => {
                    if(type.trait_type == traitType) {
                      const ooTraits = oneoff[traitType];

                      Object.keys(ooTraits).forEach(traitName => {
                        if(traitName == type.value) {
                          oneOffPath = require('../images/wallpapers/'+props.component+'/'+ooTraits[traitName]+'.png')
                        }
                      });
                    }
                });
              });
            }
            // the rest
            if(type.trait_type == props.trait) {
              let cleanTrait = type.value.replace(/\s+/g, '-').toLowerCase()
              props.setSavedTrait(cleanTrait)
                
              cleanTraitPath = require('../images/wallpapers/'+props.component+'/bg_'+cleanTrait+'.png') 
            }
          }) // map 

          props.setImageURL(imageURL)
          fetchImage(false,imageURL, cleanTraitPath,oneOffPath, 1, 0,context,canvas,props.size,props.setImageURL,props.setPlainImage,null)
        }
          // set loading?
      })
      .catch(function (error) {
        setError('Error getting assets from wallet. '+error);
    });
  };


  return (
    <div 
      onClick={() => clickAsset(props.image)}
      className="asset"
    >
      <img src={props.image} id={props.asset.identifier} />
    </div>
  );
}

export default Asset;