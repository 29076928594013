import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/home';
import Wallpaper from './components/Wallpaper';
import { getUserWalletAddress } from './components/Utils';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faLinkSlash, faMagnifyingGlass, faBullhorn } from '@fortawesome/free-solid-svg-icons';
library.add(faLink,faLinkSlash, faMagnifyingGlass, faBullhorn)

import wallpapers from './wallpapers';

function App() {
  const storedUserAddress = getUserWalletAddress();

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />

        {wallpapers.map((wallpaper) => {
          return <Route 
            key={wallpaper.path} 
            path={wallpaper.path} 
            element={<Wallpaper wallpaper={wallpaper} storedAddress={storedUserAddress} />} 
          />
        })}

      </Routes>
    </Router>
  )
}

export default App;