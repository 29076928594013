import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Photo from '../components/Photo';
import { Link } from 'react-router-dom';
import wallpapers from '../wallpapers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Home() {
  return (
    <div className="page-wrap home">
      <Header />
      <div className="home-inner container">
        <h1 className="home">NFT <strong>Wallpapers</strong></h1>
        <p>Welcome to TokenSnap, your window into the mesmerizing world of NFT art and collectibles. <strong>Choose your collection below to get started!</strong></p>

        <div className="wallpapers">
          <div className="home-boxes wallpapers">
            {wallpapers.map((wallpaper) => {
              return (
                <Link
                  key={wallpaper.path}
                  to={wallpaper.path}
                >
                  <Photo
                    key={wallpaper.path}
                    image={require(`../images/default/${wallpaper.component}.jpg`)}
                    imageAlt={wallpaper.name}
                  />
                  <span>{wallpaper.name}</span>
                </Link>
              )
            })}
          </div>
        </div>

        <div className="not-here">
          <h2>Collection not listed here?</h2>
          <p>Reach out to inquire about adding it to the list.</p>
          <Link 
            className="btn reach-out"
            to="https://www.x.com/tokensnapxyz"
          ><FontAwesomeIcon icon="fa-solid fa-bullhorn" />Reach Out!</Link>
        </div>
      </div>

      <Footer />
    </div>
  )
};

export default Home;